<template>
  <div class="appointmentList-view">
    <el-button class="el-button ml10 el-button--primary el-button--small" @click="add" style="margin-bottom: 10px">添加角色</el-button>
    <el-row :gutter="100">
      <el-col :span="16">
        <el-card>
          <commonTable
              ref="table"
              :tableData="tableData"
              @handleSizeChange="handleSizeChange"
              @handleCurrentChange="handleCurrentChange"
              :currentPage="queryParam.pageNum"
              :page-size="queryParam.pageSize"
              :loading="loading"
              :total="total"
              @handleClickRow="handleClickRow"
          >
            <template v-slot:table>
              <el-table-column prop="name" align="center" label="名称" />
              <el-table-column prop="intro" align="center" label="描述" />
              <el-table-column prop="createTime" align="center" label="创建时间" />
              <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                  <comm-operation :delId="scope.row.id" url="/company/role" @refresh="initList" @edit="toEdit(scope.row)"/>
                </template>
              </el-table-column>
            </template>
          </commonTable>
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-card>
          <div slot="header" class="clearfix">
            <span>菜单分配</span>
            <el-button style="float: right; padding: 3px 0" type="text" @click="setPermission">确定</el-button>
          </div>
          <el-tree
              ref="tree"
              :data="menuData"
              show-checkbox
              node-key="id">
          </el-tree>
        </el-card>
      </el-col>
    </el-row>

    <el-dialog :visible.sync="addDialogShow" append-to-body :title="dialogTit" width="60%">
       <el-form label-width="100px" :model="formData" :rules="rules" ref="form">
         <el-form-item label="名称" prop="name">
             <el-input placeholder="请输入名称" v-model="formData.name"></el-input>
         </el-form-item>
         <el-form-item label="描述" prop="intro">
           <el-input placeholder="请输入描述" v-model="formData.intro"></el-input>
         </el-form-item>
       </el-form>
      <div class="flex-c-c">
        <el-button @click="addDialogShow=false">取消</el-button>
        <el-button
            class="save"
            type="primary"
            @click="addConfirm">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import {get, post} from "@/utils/request";
import commTable from "@/mixins/commTable";
import CommOperation from "@/components/common/CommOperation.vue";

export default {
  name: "role",
  mixins: [commTable],
  components: {
    CommOperation,
    commonTable,
  },
  data() {
    return {
      addDialogShow: false,
      formData: {
        name :'',
        intro : ''
      },
      tableData: [],
      rules: {
        name: [
          { required: true, message: '请输入名称', trigger: 'blur' }
        ],
      },
      menuData: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      curSelRow: {menus: []},
      dialogTit: '添加角色'
    };
  },
  mounted() {
    this.initList('/company/role/list');
    this.initMenuTree()
  },
  methods: {
    toEdit(row){
      this.dialogTit='编辑角色'
      this.formData=row
      this.addDialogShow=true;
    },
    handleClickRow(row){
      get('/company/role/permissionList',{roleId: row.id}).then(res=>{
        this.$refs.tree.setCheckedKeys(res)
      })
      this.curSelRow=row
     // this.$refs.tree.setCheckedKeys(row.menus)
    },
    initMenuTree(){
      get('company/menu/list').then(res=>{
        this.menuData=res
      })
    },
    delRow(id){

    },
    add(){
      debugger
      this.formData.name = ''
      this.formData.intro = ''
      if(this.formData.id) {
        delete this.formData.id
      }
      this.dialogTit = '添加角色'
      this.addDialogShow=true;
    },
    addConfirm(){
      debugger
      console.log('this.formData', this.formData)
      this.$refs.form.validate((valid) => {
         if(valid){
           post('/company/role/save',this.formData).then(res=>{
             this.addDialogShow=false
             this.initList()
           })
         }
      })
    },
    setPermission(){
      console.log('ss',this.curSelRow,this.curSelRow.id)
      if(!this.curSelRow.id){
        this.$message.error('请先选择角色')
        return;
      }
      let halfKeys =this.$refs.tree.getHalfCheckedKeys()
      let checkKeys=this.$refs.tree.getCheckedKeys()
      let keys = [...halfKeys, ...checkKeys]
      let params={
        menuIds: keys,
        roleId: this.curSelRow.id,
      }
      post('/company/role/setMenu',params).then(res => {
          this.$message.success('操作成功')
          this.initList()
      })
    }

  },
};
</script>

<style lang="scss" scoped>
.appointmentList-view {
  background: #ffffff;
  padding: 24px;
}
</style>
