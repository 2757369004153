import Vue from "vue";
import Router from "vue-router";
import ParentView from '@/components/ParentView'

Vue.use(Router);

const Home=() => import("../components/Home.vue")

// // 解决vue-router在3.0版本以上重复报错问题
// const originalPush = Router.prototype.push
// Router.prototype.push = function push(location) {
//     return originalPush.call(this, location).catch(err => err)
// }
// const originalPush = Router.prototype.push
// Router.prototype.push = function push(location) {
//     return originalPush.call(this, location).catch(err => err)
// }

export const constRouter = [

    {
        path: "/",
        component: Home,
        meta: {title: "工作台",hideParent: true},
        redirect: "/dashboard",
        children: [
            {
                path: "/dashboard",
                component: () => import("../pages/dashboard"),
                meta: {title: "工作台", keepAlive: false, icon:'#icon-dashboard'}
            }
        ]
    },
    {
        path: "/userVerify",
        component: () => import("../pages/login/userVerify"),
        meta: {title: "用户验证", keepAlive: false},
        hidden: true
    },
    {
        path: '/login',
        component: () => import('../pages/login'),
        meta: {title: '登录'},
        hidden: true
    },
    {
        path: '/bindAccount',
        component: () => import('../pages/bindAccount'),
        meta: {title: '绑定账号'},
        hidden: true
    },
    {
        path: '/verManage',
        component: () => import('../pages/verManage/index'),
        meta: {title: '更新日志', keepAlive: false},
        hidden: true
    },
    {
        path: "/down",
        component: () => import("../pages/downPage/index"),
        meta: {title: "APP下载", keepAlive: false},
        hidden: true
    },
    {
        path: "/crkDown",
        component: () => import("../pages/downPage/crkIndex"),
        meta: {title: "出入库APP下载", keepAlive: false},
        hidden: true
    },
]

export default new Router({
    mode: 'history',
    routes: constRouter
    // routes: [

    //     {
    //         path: "/",
    //         component: () => import("../components/Home"),
    //         meta: {title: "home"},
    //         children: [


    //             {
    //                 path: 'launchProducts',
    //                 component: () => import('../pages/merchandiseManagement/appointment/management'),
    //                 meta: {title: '发布产品', keepAlive: false}
    //             },


    //             // {
    //             // 	path: 'websiteSettings',
    //             // 	component: () => import('../pages/businessManagement/websiteSettings'),
    //             // 	meta: { title: '官网设置' }
    //             // },


    //             {
    //                 path: 'agentManage',
    //                 component: () => import('../pages/agentManage/agentManage'),
    //                 meta: {title: '代理企业', keepAlive: false}
    //             },
    //             {
    //                 path: 'companyDetail',
    //                 component: () => import('../pages/agentManage/companyDetail'),
    //                 meta: {title: '代理企业详情', keepAlive: false}
    //             },


    //             {
    //                 path: 'appointmentManagement',
    //                 component: () => import('../pages/merchandiseManagement/appointmentManagement'),
    //                 meta: {title: '预约管理', keepAlive: false}
    //             },


    //


    //
    //             // {
    //             // 	path:'myNeeds',
    //             // 	component: () => import('../pages/addedServices/myNeeds'),
    //             // 	meta: { title: '我的需求',keepAlive: false }
    //             // },
    //             // {
    //             // 	path:'demandCheck',
    //             // 	component: () => import('../pages/addedServices/demandCheck'),
    //             // 	meta: { title: '需求审核',keepAlive: false }
    //             // },
    //             // {
    //             // 	path:'demandContactsCheck',
    //             // 	component: () => import('../pages/addedServices/demandContactsCheck'),
    //             // 	meta: { title: '人脉集市审核',keepAlive: false }
    //             // },
    //             // {
    //             // 	path:'demandProtuctsCheck',
    //             // 	component: () => import('../pages/addedServices/demandProtuctsCheck'),
    //             // 	meta: { title: '产品中心审核',keepAlive: false }
    //             // },
    //             // {
    //             // 	path:'demandPublishSetting',
    //             // 	component: () => import('../pages/addedServices/demandPublishSetting'),
    //             // 	meta: { title: '发布设置',keepAlive: false }
    //             // },


    //             {
    //                 path: 'demandPublishForm',
    //                 component: () => import('../pages/addedServices/components/demandPublishForm'),
    //                 meta: {title: '发布需求', keepAlive: false}
    //             },
    //             {
    //                 path: 'posterAtlas',
    //                 component: () => import('../pages/addedServices/sharingPromotion/posterAtlas'),
    //                 meta: {title: '海报图集', keepAlive: false}
    //             },
    //             {
    //                 path: 'posterAtlasDetall',
    //                 component: () => import('../pages/addedServices/sharingPromotion/posterAtlasDetall'),
    //                 meta: {title: '海报图集详情', keepAlive: false}
    //             },
    //             {
    //                 path: 'clockData',
    //                 component: () => import('../pages/addedServices/clockData'),
    //                 meta: {title: '打卡数据', keepAlive: false}
    //             },
    //             {
    //                 path: 'clockDataDetall',
    //                 component: () => import('../pages/addedServices/clockData/clockDataDetall'),
    //                 meta: {title: '打卡详情', keepAlive: false}
    //             },
    //             {
    //                 path: 'appletConfig',
    //                 component: () => import('../pages/miniProgram/appletConfig'),
    //                 meta: {title: '小程序配置', keepAlive: false}
    //             },
    //             // {
    //             // 	path:'basicSetting',
    //             // 	component: () => import('../pages/miniProgram/basicSetting'),
    //             // 	meta: {
    //             // 		title: '基础设置',
    //             // 		keepAlive: false
    //             //  	}
    //             // },
    //             // {
    //             // 	path:'miniProgram',
    //             // 	component: () => import('../pages/miniProgram'),
    //             // 	meta: { title: '小程序配置' }
    //             // },

    //

    //             // 公众号相关
    //
    //             // 模板管理添加

    //             // 自定义模版列表


    //             // 创建页面列表

    //             // 自定义操作员页面
    //             // {
    //             // 	path:'createOperatorPage',
    //             // 	component: () => import('../pages/operatorPage/index'),
    //             // 	meta: { title: '创建操作员界面',keepAlive: false }
    //             // },
    //             //  标识页面列表


    //             {
    //                 path: 'customerHandleList',
    //                 component: () => import('../pages/handleCustomer/components/handleList'),
    //                 meta: {title: '客户标识数据', keepAlive: false}
    //             },


    //             {
    //                 path: 'fitup',
    //                 component: () => import('../pages/shop/fitup'),
    //                 meta: {title: '店铺装修', keepAlive: false}
    //             },
    //             {
    //                 path: 'goodGroup',
    //                 component: () => import('../pages/goodGroup/index'),
    //                 meta: {title: '商品组管理', keepAlive: false}
    //             },
    //             // {
    //             // 	path:'citlnTemplate',
    //             // 	component: () => import('../pages/citlnTemplateManage/index'),
    //             // 	meta: { title: '',keepAlive: false }
    //             // }


    //             {
    //                 path: '/rytStatistics',
    //                 component: () => import('../pages/ryt/rytStatistics'),
    //                 meta: {title: '任意贴统计数据', keepAlive: false}
    //             },
    //
    //         ]
    //     },
    //
    //     // 模板管理查看


    //     {
    //         path: "*",
    //         redirect: "/dashboard"
    //     }
    // ]
})

//const USER=() => import('../pages/businessManagement/user')

export const asyncRouters = [
    {
        path: '/custom',
        component: Home,
        meta: {permission: 'custom', title: '客户管理', icon:'#icon-team'},
        children: [
            {
                path: "management",
                component: () => import("../pages/customer/customerManagement"),
                meta: {title: "客户管理", keepAlive: false, permission: 'customList'}
            },
            {
                path: "detail",
                component: () => import("../pages/customer/customerDetall"),
                meta: {title: "客户详情", keepAlive: false, permission: 'customList'},
                hidden: true
            },
            {
                path: "add",
                component: () => import("../pages/customer/customerInput"),
                meta: {title: "客户录入", keepAlive: false, permission: 'customList'},
                hidden: true
            },
        ]
    },
    {
        path: '/ent',
        component: Home,
        meta: {permission: 'ent', title: '企业管理',icon:'#icon-bank'},
        children: [
            {
                path: 'corporateInformation',
                component: () => import('../pages/businessManagement/corporateInformation'),
                meta: {title: '企业信息', keepAlive: false, permission: 'entInfo'}
            },
            {
                path: 'enterpriseMember',
                component: () => import('../pages/businessManagement/enterpriseMember'),
                meta: {title: '企业成员', keepAlive: false, permission: 'entUser'}
            },
            {
                path: 'memberDetail',
                component: () => import('../pages/businessManagement/memberDetall'),
                meta: {title: '成员详情', keepAlive: false, permission: 'staff'},
                hidden: true
            },
            {
                path: 'sectorSetup',
                component: () => import('../pages/businessManagement/sectorSetup/sectorSetup'),
                meta: {title: '部门设置', keepAlive: false, permission: 'staff'},
                hidden: true
            },
            {
                path: 'AdministratorList',
                component: () => import('../pages/businessManagement/AdministratorList/AdministratorList'),
                meta: {title: '管理员列表', keepAlive: false, permission: 'entUser'},
                hidden: true
            },
            {
                path: 'AdministratorDetail',
                component: () => import('../pages/businessManagement/AdministratorList/AdministratorDetail'),
                meta: {title: '管理员设置', keepAlive: false, permission: 'entUser'},
                hidden: true
            },
            {
                path: 'advancedForm',
                component: () => import('../pages/businessManagement/advancedForm'),
                meta: {title: '自定义反馈表', keepAlive: false, permission: 'feedback'}
            },
            {
                path: 'addForm',
                component: () => import('../pages/businessManagement/publishAdvancedForm'),
                meta: {title: '新增表单', keepAlive: false, permission: 'feedback'},
                hidden: true
            },
            {
                path: 'formDetail',
                name: 'formDetall',
                component: () => import('../pages/businessManagement/formDetall'),
                meta: {title: '表单详情', keepAlive: false, permission: 'feedback'},
                hidden: true
            },
            {
                path: 'advertisingManagement',
                component: () => import('../pages/businessManagement/advertisingManagement'),
                meta: {title: '广告图管理', keepAlive: false, permission: 'carousel'}
            },
            {
                path: 'setBanner',
                component: () => import('../pages/businessManagement/setBanner'),
                meta: {title: '广告图设置', keepAlive: false, permission: 'carousel'},
                hidden: true
            },
            {
                path: 'dealerManage',
                component: () => import('../pages/dealerManage/main'),
                meta: {title: '经销商管理', keepAlive: false, permission: 'dealer'}
            },
            {
                path: 'role',
                component: () => import('../pages/businessManagement/role.vue'),
                meta: {title: '角色管理', keepAlive: false, permission: 'entRole'}
            },
            {
                path: 'user',
                component: () => import('../pages/businessManagement/user.vue'),
                meta: {title: '员工管理', keepAlive: false, permission: 'staff'}
            },
        ]
    },
    {
        path: '/order',
        component: Home,
        meta: {permission: 'order', title: '订单管理',icon:'#icon-file-text'},
        children: [
            {
                path: 'orderManagement',
                component: () => import('../pages/orderManagement'),
                meta: {title: '订单管理', keepAlive: false, permission: 'orderList'}
            },
            {
                path: 'evaluateManagement',
                component: () => import('../pages/evaluateManagement'),
                meta: {title: '评价管理', keepAlive: false, permission: 'evaluate'}
            },
            {
                path: 'noticeManagement',
                component: () => import('../pages/noticeManagement/companyNotice'),
                meta: {title: '通知管理', keepAlive: false, permission: 'notice'}
            }
        ]
    },
    {
        path: '/good',
        component: Home,
        meta: {permission: 'good', title: '商品管理',icon:'#icon-shopping'},
        children: [
            {
                path: 'management',
                component: () => import('../pages/merchandiseManagement/management'),
                meta: {title: '商品列表', keepAlive: false, permission: 'goodList'}
            },
            {
                path: 'redactOrAdd',
                component: () => import('../pages/goods/redactOrAdd'),
                meta: {title: '商品发布', keepAlive: false, permission: 'goodList'},
                hidden: true
            },
        ]
    },
    {
        path: '/marketing',
        component: Home,
        meta: {permission: 'marketing', title: '营销活动',icon:'#icon-gift'},
        children: [
            {
                path: 'management',
                component: ParentView,
                meta: {title: '分销', keepAlive: false, permission: 'distribution'},
                children:
                    [
                        {
                            path: 'distributionStatistics',
                            component: () => import('../pages/marketingActivities/distribution/distributionStatistics'),
                            meta: {title: '分销统计', keepAlive: false, permission: 'fxStatistics'}
                        },
                        {
                            path: 'personnelApplication',
                            component: () => import('../pages/marketingActivities/distribution/personnelApplication'),
                            meta: {title: '人员申请', keepAlive: false, permission: 'userApply'}
                        },
                        {
                            path: 'distributionLevel',
                            component: () => import('../pages/marketingActivities/distribution/distributionLevel'),
                            meta: {title: '分销等级', keepAlive: false, permission: 'fxLevel'}
                        },
                        {
                            path: 'distributionOrder',
                            component: () => import('../pages/marketingActivities/distribution/distributionOrder'),
                            meta: {title: '分销订单', keepAlive: false, permission: 'fxOrder'}
                        },
                        {
                            path: 'accountTransfer',
                            component: () => import('../pages/marketingActivities/distribution/accountTransfer'),
                            meta: {title: '到账流水', keepAlive: false, permission: 'accountLog'}
                        },
                    ]
            },
            {
                path: 'group',
                component: ParentView,
                meta: {title: '拼团', keepAlive: false, permission: 'groupSet'},
                children:
                    [
                        {
                            path: 'good',
                            component: () => import('../pages/marketingActivities/joinGroup/productList'),
                            meta: {title: '拼团商品列表', keepAlive: false, permission: 'groupGood'}
                        },
                        {
                            path: 'addAssemble',
                            component: () => import('../pages/marketingActivities/joinGroup/components/addAssemble'),
                            meta: {title: '新增拼团', keepAlive: false, permission: 'groupGood'},
                            hidden: true
                        },
                        {
                            path: 'listParticipants',
                            component: () => import('../pages/marketingActivities/joinGroup/listParticipants'),
                            meta: {title: '参团列表', keepAlive: false, permission: 'groupOrder'}
                        },
                        {
                            path: 'assembleOrdersInfo',
                            component: () => import('../pages/marketingActivities/joinGroup/assembleOrdersInfo'),
                            meta: {title: '订单详情', keepAlive: false, permission: 'groupOrder'},
                            hidden: true
                        },

                    ]
            },
            {
                path: 'kill',
                component: ParentView,
                meta: {title: '秒杀设置', keepAlive: false, permission: 'killSet'},
                children:
                    [
                        {
                            path: 'list',
                            component: () => import('../pages/marketingActivities/spike'),
                            meta: {title: '秒杀商品列表', keepAlive: false, permission: 'killList'}
                        },
                        {
                            path: 'addSpike',
                            component: () => import('../pages/marketingActivities/spike/components/addSpike'),
                            meta: {title: '秒杀商品', keepAlive: false, permission: 'killList'},
                            hidden: true
                        },
                    ]
            },
            {
                path: 'coupon',
                component: ParentView,
                meta: {title: '优惠券', keepAlive: false, permission: 'coupon'},
                children:
                    [
                        {
                            path: 'couponList',
                            component: () => import('../pages/marketingActivities/couponManagement/couponList'),
                            meta: {title: '优惠券列表', keepAlive: false, permission: 'couponList'}
                        },
                        {
                            path: 'detailCoupon',
                            component: () => import('../pages/marketingActivities/couponManagement/detailCoupon'),
                            meta: {title: '领取详情', keepAlive: false, permission: 'couponList'}
                        },
                    ]
            },
            {
                path: 'newAction',
                component: ParentView, //() => import('../pages/order/reductionActivity'),
                meta: {title: '满减活动', keepAlive: false, permission: 'subActivity'},
                children:
                    [
                        {
                            path: 'newAction',
                            component: () => import('../pages/order/reductionActivity'),
                            meta: {title: '满减活动', keepAlive: false, permission: 'couponList'}
                        },
                    ]
            }
        ]
    },
    {
        path: '/finance',
        component: Home,
        meta: {permission: 'finance', title: '财务管理',icon:'#icon-creditcard'},
        children: [
            {
                path: 'commissionManagement',
                component: () => import('../pages/financialManagement/commissionManagement'),
                meta: {title: '佣金管理', keepAlive: false, permission: 'commission'}
            },
            {
                path: 'commissionDetail',
                component: () => import('../pages/financialManagement/personalCommissionDetall'),
                meta: {title: '个人佣金详情', keepAlive: false, permission: 'commission'},
                hidden: true
            },
            {
                path: 'merchantApply',
                component: () => import('../pages/financialManagement/merchantApply'),
                meta: {title: '商户管理', keepAlive: false, permission: 'merchant'}
            },
            {
                path: 'merchantApplyInfo',
                name: 'merchantApplyInfo',
                component: () => import('../pages/financialManagement/components/merchantApplyInfo'),
                meta: {title: '信息填写', keepAlive: false, permission: 'merchant'},
                hidden: true
            },
            {
                path: 'merchantSubjectInfo',
                component: () => import('../pages/financialManagement/components/merchantSubjectInfo'),
                meta: {title: '主体信息', keepAlive: false, permission: 'merchant'},
                hidden: true
            },
            {
                path: 'managementInfo',
                component: () => import('../pages/financialManagement/components/managementInfo'),
                meta: {title: '经营信息', keepAlive: false, permission: 'merchant'},
                hidden: true
            },
            {
                path: 'contactInfo',
                component: () => import('../pages/financialManagement/components/contactInfo'),
                meta: {title: '超级管理员信息', keepAlive: false, permission: 'merchant'},
                hidden: true
            },
            {
                path: 'bankAccountInfo',
                component: () => import('../pages/financialManagement/components/BankAccountInfo'),
                meta: {title: '结算账户信息', keepAlive: false, permission: 'merchant'},
                hidden: true
            },
            {
                path: 'additionInfo',
                component: () => import('../pages/financialManagement/components/additionInfo'),
                meta: {title: '补充材料', keepAlive: false, permission: 'merchant'},
                hidden: true
            },
            {
                path: 'settlementInfo',
                component: () => import('../pages/financialManagement/components/settlementInfo'),
                meta: {title: '行业资质', keepAlive: false, permission: 'merchant'},
                hidden: true
            },
        ]
    },
    {
        path: '/video',
        component: Home,
        meta: {permission: 'video', title: '短视频管理',hideParent: true},
        children: [
            {
                path: 'videoManage',
                component: () => import('../pages/videoManage/index'),
                meta: {title: '短视频管理', keepAlive: false, permission: 'videoManage',icon:'#icon-video'}
            }
        ]
    },
    {
        path: '/article',
        component: Home,
        meta: {permission: 'article', title: '获客文章管理', icon:'#icon-solution'},
        children: [
            {
                path: 'customerArticles',
                component: () => import('../pages/articleManagement/customerArticles'),
                meta: {title: '获客文章', keepAlive: false, permission: 'articleList'}
            },
            {
                path: 'isDrafts',
                component: () => import('../pages/articleManagement/isDrafts'),
                meta: {title: '获客文章草稿箱', keepAlive: false, permission: 'articleList'},
                hidden: true
            },
            {
                path: 'employeeData',
                component: () => import('../pages/articleManagement/employeeData'),
                meta: {title: '员工数据', keepAlive: false, permission: 'articleUser'}
            },
            {
                path: 'staffDataDetail',
                component: () => import('../pages/articleManagement/components/staffDataDetail'),
                meta: {title: '员工数据', keepAlive: false, permission: 'articleUser'},
                hidden: true
            },

            {
                path: 'visitorData',
                component: () => import('../pages/articleManagement/visitorData'),
                meta: {title: '访客数据', keepAlive: false, permission: 'articleVisitor'}
            }
        ]
    },
    {
        path: '/shop',
        component: Home,
        meta: {permission: 'shop', title: '商城设置',icon: '#icon-api'},
        children: [
            {
                path: 'setting',
                component: () => import('../pages/miniProgram/mallSetting'),
                meta: {title: '商城设置', keepAlive: true, permission: 'shopSetting'}
            },
            // {
            // 	path:'mallTemplate',
            // 	component: () => import('../pages/miniProgram/mallTemplate'),
            // 	meta: { title: '商城模板设置',keepAlive: false }
            // },
            {
                path: 'templateA',
                component: () => import('../pages/miniProgram/templateA'),
                meta: {title: '综合电商', keepAlive: true, permission: 'shopSetting'},
                hidden: true
            },
            {
                path: 'templateB',
                component: () => import('../pages/miniProgram/templateB'),
                meta: {title: '节日优惠', keepAlive: true, permission: 'shopSetting'},
                hidden: true
            },
            {
                path: 'templateC',
                component: () => import('../pages/miniProgram/templateC'),
                meta: {title: '日用百货', keepAlive: true, permission: 'shopSetting'},
                hidden: true
            },
            {
                path: 'templateD',
                component: () => import('../pages/miniProgram/templateD'),
                meta: {title: '数码家电', keepAlive: true, permission: 'shopSetting'},
                hidden: true
            },
            {
                path: 'freight',
                component: () => import('../pages/order/freightNew'),
                meta: {title: '运费管理', keepAlive: false, permission: 'fright'}
            },
        ]
    },
    {
        path: '/mkc',
        component: Home,
        meta: {permission: 'mkc', title: '模版管理',icon:'#icon-CI'},
        children: [
            {
                path: 'class',
                component: () => import('../pages/proClass/index'),
                meta: {title: '产品类别', keepAlive: false, permission: 'class'}
            },
            // {
            //     path: 'proClass',
            //     component: () => import('../pages/proClass/index'),
            //     meta: {title: '产品类别（新）', keepAlive: false, permission: 'class'}
            // },
            {
                path: 'model',
                component: () => import('../pages/customPageList/customlistIndex'),
                meta: {title: '模板列表', keepAlive: false, permission: 'model'}
            },
            // {
            //     path: 'list',
            //     component: () => import('../pages/mkc/list.vue'),
            //     meta: { title: '码客查' ,keepAlive: false, permission: 'mkc' }
            // },
            {
                path: 'customPage',
                component: () => import('../pages/customPage/customIndex'),
                meta: {title: '创建页面', keepAlive: false, permission: 'model'},
                hidden: true
            },
            {
                path: 'customPageShow',
                component: () => import('../pages/customPageList/customPageShow'),
                meta: {title: '页面查看', keepAlive: false, permission: 'model'},
                hidden: true
            },
            {
                path: 'page',
                component: () => import('../pages/createPageList/createPageIndex'),
                meta: {title: '页面列表', keepAlive: true, permission: 'page'}
            },
            {
                path: 'handlePageList',
                component: () => import('../pages/handlePageList/handlePageIndex'),
                meta: {title: '标识页面列表', keepAlive: false, permission: 'page'},
                hidden: true
            },
            {
                path: 'verPageList',
                component: () => import('../pages/verPageList/verPageIndex'),
                meta: {title: '验证记录', keepAlive: false, permission: 'page'},
                hidden: true
            },
            {
                path: 'pageVipRecord',
                component: () => import('../pages/vip/record'),
                meta: {title: '积分扫码记录', keepAlive: false, permission: 'page'},
                hidden: true
            },

            {
                path: 'dataModel',
                component: () => import('../pages/configHandleType/index'),
                meta: {title: '数据模版', keepAlive: false, permission: 'dataModel'}
            },
            {
                path: 'configHandleTypeAdd',
                component: () => import('../pages/configHandleType/add'),
                meta: {title: '新增数据模版', keepAlive: false, permission: 'dataModel'},
                hidden: true
            },
            {
                path: 'handleModel',
                component: () => import('../pages/configTemplate/index'),
                meta: {title: '标识模版', keepAlive: false, permission: 'handleModel'}
            },
            {
                path: 'configTemplateAdd',
                component: () => import('../pages/configTemplate/add'),
                meta: {title: '新增标识模版', keepAlive: false, permission: 'handleModel'},
                hidden: true
            },
            {
                path: 'configTemplateDetail',
                component: () => import('../pages/configTemplate/detail'),
                meta: {title: '标识模版详情', keepAlive: false, permission: 'handleModel'},
                hidden: true
            },
            {
                path: 'imgManage',
                component: () => import('../pages/pageClass/imgList'),
                meta: {title: '图片管理', keepAlive: false, permission: 'img'}
            }
        ]
    },
    {
        path: '/public',
        component: Home,
        meta: {permission: 'public', title: '公众号', hideParent: true},
        children: [
            {
                path: 'about',
                component: () => import('../pages/publicAbout/index'),
                meta: {title: '公众号相关', keepAlive: false, permission: 'publicAbout',icon:'#icon-smile'}
            }
        ]
    },
    {
        path: '/member',
        component: Home,
        meta: {permission: 'member', title: '会员管理',icon: '#icon-check-square'},
        children: [
            {
                path: 'list',
                component: () => import('../pages/vipManage/vipMain'),
                meta: {title: '会员管理', keepAlive: false, permission: 'memberList'}
            },
            {
                path: 'integral',
                component: () => import('../pages/vipManage/vipRecord'),
                meta: {title: '会员积分记录', keepAlive: false, permission: 'memberList'},
                hidden: true
            },
            {
                path: 'scan',
                component: () => import('../pages/vipManage/scanRecord'),
                meta: {title: '会员扫码记录', keepAlive: false, permission: 'memberList'},
                hidden: true
            }
        ]
    },
    {
        path: '/task',
        component: Home,
        meta: {permission: 'task', title: '任务管理',icon: '#icon-project'},
        children: [
            {
                path: 'specialIndex',
                component: () => import('../pages/printManage/specialIndex'),
                meta: {title: '箱盒任务', keepAlive: false, permission: 'boxTask'}
            },
            {
                path: 'printIndex',
                component: () => import('../pages/printManage/printIndex'),
                meta: {title: '设备管理', keepAlive: false, permission: 'device'}
            },
        ]
    },
    {
        path: '/ryt',
        component: Home,
        meta: {permission: 'ryt', title: '任意贴',icon:'#icon-qrcode'},
        children: [
            {
                path: 'rytHandle',
                component: () => import('../pages/ryt/rytHandle'),
                meta: {title: '任意贴标识码', keepAlive: false, permission: 'rytHandle'}
            },
            {
                path: 'edit',
                component: () => import('../pages/ryt/edit.vue'),
                meta: {title: '编辑任意贴', keepAlive: false, permission: 'rytHandle'},
                hidden: true
            },
            {
                path: 'handleIndex',
                component: () => import('../pages/ryt/handleIndex'),
                meta: {title: '任意贴标识码列表', keepAlive: false, permission: 'rytHandle'},
                hidden: true
            },
            {
                path: 'rytModel',
                component: () => import('../pages/ryt/rytModel'),
                meta: {title: '任意贴模板', keepAlive: false, permission: 'rytModel'}
            },
        ]
    },
		{
			path: '/mail',
			component: Home,
			meta: {
				permission: 'mail',
				title: '邮件管理',
				icon: '#icon-qrcode'
			},
			children: [{
					path: 'mailIndex',
					component: () => import('../pages/mail/mailIndex'),
					meta: {
						title: '邮箱管理',
						keepAlive: false,
						permission: 'mailIndex'
					}
				}
			]
		},
    {
        path: '/crk',
        component: Home,
        meta: {permission: 'crk', title: '出入库管理',icon:'#icon-star'},
        children: [
            {
                path: 'strategy',
                component: () => import('../pages/strategy/index'),
                meta: {title: '参数配置', keepAlive: false, permission: 'crkStrategy'}
            },
            {
                path: 'dailiManage',
                component: () => import('../pages/dealerManage/daili'),
                meta: {title: '代理商管理', keepAlive: false, permission: 'crkAgent'}
            },
            // {
            //     path: 'depManage',
            //     component: () => import('../pages/department/index'),
            //     meta: {title: '部门管理', keepAlive: false, permission: 'crkAgent'}
            // },
            {
                path: 'dealerManage',
                component: () => import('../pages/dealerManage/main'),
                meta: {title: '经销商管理', keepAlive: false, permission: 'crkDealer'}
            },
            // {
            //     path: 'structure',
            //     component: () => import('../pages/structure/index'),
            //     meta: {title: '仓库结构', keepAlive: false, permission: 'crkStore'}
            // },
            // {
            //     path: 'area',
            //     component: () => import('../pages/structure/home'),
            //     meta: {title: '仓库/区/位', keepAlive: false, permission: 'crkStore'}
            // },
            {
                path: 'warehouse',
                component: () => import('../pages/warehouse/main'),
                meta: {title: '仓库管理', keepAlive: false, permission: 'crkStore'}
            },
            {
                path: 'warehouseLogIn',
                component: () => import('../pages/warehouseLog/putIn'),
                meta: {title: '入库记录', keepAlive: false, permission: 'inRecord'}
            },
            {
                path: 'warehouseLog/inDetail',
                component: () => import('../pages/warehouseLog/putInDetail'),
                meta: {title: '入库详情', keepAlive: false, permission: 'inRecord'},
                hidden: true
            },

            {
                path: 'warehouseLogOut',
                component: () => import('../pages/warehouseLog/out'),
                meta: {title: '出库记录', keepAlive: false, permission: 'outRecord'}
            },
            {
                path: 'warehouseLog/outDetail',
                component: () => import('../pages/warehouseLog/outDetail'),
                meta: {title: '出库记录详情', keepAlive: false, permission: 'outRecord'},
                hidden: true
            },
            {
                path: 'backLog',
                component: () => import('../pages/warehouseLog/back'),
                meta: {title: '退货记录', keepAlive: false, permission: 'backRecord'}
            },
            {
                path: 'warehouseLog/backDetail',
                component: () => import('../pages/warehouseLog/backDetail'),
                meta: {title: '退货记录详情', keepAlive: false, permission: 'backRecord'},
                hidden: true
            },
            {
                path: 'unbindLog',
                component: () => import('../pages/warehouseLog/unbind'),
                meta: {title: '解绑记录', keepAlive: false, permission: 'unbindRecord'}
            },
            {
                path: 'unbindLog/detail',
                component: () => import('../pages/warehouseLog/unbindDetail'),
                meta: {title: '解绑记录详情', keepAlive: false, permission: 'unbindRecord'},
                hidden: true
            },
            {
                path: 'operator',
                component: () => import('../pages/operator/main'),
                meta: {title: '操作员管理', keepAlive: false, permission: 'crkOperate'}
            },
            {
                path: 'crkReceive',
                component: () => import('../pages/crkReceive/main'),
                meta: {title: '待收货列表', keepAlive: false, permission: 'waitOrder'}
            },
            {
                path: 'crkProduce',
                component: () => import('../pages/crkProduce/main'),
                meta: {title: '入库单管理', keepAlive: false, permission: 'produce'}
            },
            {
                path: 'crkProduceAdd',
                component: () => import('../pages/crkProduce/add'),
                meta: {title: '添加入库单', keepAlive: false, permission: 'produce'},
                hidden: true
            },
            {
                path: 'ckOrder',
                component: () => import('../pages/ckOrder/main'),
                meta: {title: '出库单管理', keepAlive: false, permission: 'ckOrder'}
            },
            {
                path: 'ckOrderAdd',
                component: () => import('../pages/ckOrder/add'),
                meta: {title: '添加出库单', keepAlive: false, permission: 'ckOrder'},
                hidden: true
            },

            {
                path: 'inventory',
                component: () => import('../pages/inventory/index'),
                meta: {title: '仓储管理', keepAlive: false, permission: 'inventory'}
            },
            {
                path: 'gps',
                component: () => import('../pages/gps/index'),
                meta: {title: 'gps轨迹', keepAlive: false, permission: 'gps'}
            },
            {
                path: 'gpsDevice',
                component: () => import('../pages/gpsDevice/index'),
                meta: {title: 'gps设备管理', keepAlive: false, permission: 'gps'}
            },
        ],

    },
    {
        path: '/print',
        component: Home,
        meta: {permission: 'job', title: '产线信息管理',icon:'#icon-file'},
        children: [
            {
                path: 'job',
                component: () => import('../pages/printJob/main'),
                meta: {title: '打印任务管理', keepAlive: false, permission: 'jobList'}
            },
            {
                path: 'produce',
                component: () => import('../pages/printProduce/main'),
                meta: {title: '产线产品信息', keepAlive: false, permission: 'printProduce'}
            },
        ]
    },
    {
        path: '/winning',
        component: Home,
        meta: {permission: 'winningMannager', title: '营销管理',icon:'#icon-file'},
        children: [
            {
                path: 'list',
                component: () => import('../pages/winning/list'),
                meta: {title: '奖项设置', keepAlive: false, permission: 'winning'}
            },
            {
                path: '',
                component: () => import('../pages/winning/winningLog'),
                meta: {title: '中奖记录', keepAlive: false, permission: 'winningLog'}
            },
        ]
    },
    // {
    //     path: '/schedule',
    //     component: Home,
    //     meta: {permission: 'ent', title: '日程管理',icon:'#icon-bank'},
    //     children: [
    //         {
    //             path: 'calendar',
    //             component: () => import('../pages/schedule/calendar'),
    //             meta: {title: '日程展示', keepAlive: false, permission: 'staff'}
    //         },
    //         {
    //             path: 'main',
    //             component: () => import('../pages/schedule/main'),
    //             meta: {title: '日程列表', keepAlive: false, permission: 'staff'}
    //         },
    //     ]
    // }
]
