import httpUtil from '../utils/httpUtil'


/**
 * 密码登录
 * type : 是否显示loading
 */
 export function fetchLoginByPhone(params,type) {
	return httpUtil.post("/home/crmPcLoginByPhone", params,type)
}

/**
 * 体验登录
 * type : 是否显示loading
 */
export function fetchLogin(params,type) {
	return httpUtil.post("/home/crmPcLoginBySms", params,type)
}


/**
 * 获取验证码
 */
export function fetchVeriCode(params,type) {
	return httpUtil.post("/home/sendSms", params , type)
}

/**
 * 查询公司
 */
export function fetchCompany(params,type) {
	return httpUtil.post("/home/getCompanyListByPhone", params, type)
}



/**
 * 获取登录code码
 */
 export function fetchCodeUrl(params,type) {
	return httpUtil.post("/home/getPcLoginImg", params, type)
}

/**
 * 扫码成功后回调码
 */
 export function pcLoginCallBack(params,type) {
	return httpUtil.post("/home/pcLoginCallBack", params, type)
}

/**
 * 图形验证码
 */
 export function getVerifiCode(params,type) {
	return httpUtil.post("/home/getVerifiCode", params, type)
}

/**
 * 获取认证签名
 */
 export function forgetPwd(params,type) {
	return httpUtil.post("/home/forgetPwd", params, type)
}


/**
 * 修改密码
 */
 export function updatePwd(params,type) {
	return httpUtil.post("/home/updatePwd", params, type)
}

/**
 * 绑定用户
 */
 export function bindUser(params,type) {
	return httpUtil.post("/home/bindUser", params, type)
}


/****获取菜单 */
export function fetchMenuList(params) {
	return httpUtil.get("/company/menu/list", params)
}

/***获取tab栏 */

export function fetchTabsList(params) {
	return httpUtil.get("/company/getTabList", params)
}


export default {
	bindUser,
	fetchLogin,
	fetchVeriCode,
	fetchCompany,
	fetchCodeUrl,
	pcLoginCallBack,
	getVerifiCode,
	forgetPwd,
	updatePwd,
	fetchLoginByPhone
}
