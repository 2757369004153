import Vue from 'vue'
import Vuex from 'vuex'
import loginRoot from './loginRoot'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        isShowLoading: false, // 全局 loading
        subjectColor:'#FFFFFF', //主题颜色
        tagColor:'#51CBCD',
        goodsInfo:{},
        // http://10.10.0.191:8666/
        uploadFileUrl: '/api/customPage/uploadAppointFile',
        uploadingUrl:'/api/file/uploadObjectOSS', //上传域名 \http://crm.86122m.com/api/file/uploadObjectOSS
        ossUrl:'https://mzmpic.oss-cn-beijing.aliyuncs.com/', //静态资源域名
        uploadCustomUrl: '/api/file/uploadCustom', // 自定义上传页面,上传图片域名
        // uploadCustomUrl: 'https://api.86122m.com/file/uploadCustom', // 自定义上传页面,上传图片域名
        routerArr:[],
        empId:'',//部门id
        hasRole: false,
        asyncMenus: [],
        btnList: ['entStatistics']
    },
    mutations: {
        SET_GOODSINFO(state, data) {
            state.goodsInfo = data;
        },
        setRouterArr(state,data){
            state.routerArr = data
        },
        setEmpId(state,data){
            state.empId = data
        },
        setBtnList(state,btnList){
            state.btnList=btnList
        }
    },
    modules:{
        loginRoot
    },
    getters:{
      asyncMenus: state => state.asyncMenus,
        btnList: state=>state.btnList
    },
    actions:{
        setHasRole({state},hasRole){
            state.hasRole=hasRole
        },
        setAsyncMenus({state},meuns){
            state.asyncMenus=meuns
        },
        setBtnList({state},btnList){
            state.btnList=btnList
        }
    }
})

export default store
