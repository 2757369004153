import store from "@/store";


export  function  checkPermission(value){
    const btnList = store.state && store.state.btnList
    if (value && value instanceof Array) {
        if (value.length > 0) {
            const permissionRoles = value
            return btnList.some(role => {
               return permissionRoles.includes(role)
           });
        }
    } else {
        throw new Error(`使用方式： v-permission="['admin','editor']"`)
    }
}

export  default {
    inserted(el, binding, vnode, oldVnode) {
        const { value } = binding
        if(!checkPermission(value)){
            el.parentNode && el.parentNode.removeChild(el)
        }
    }
}