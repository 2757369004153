import router from '../router';
export function clearLoginInfo() {
  /* wx.showToast({
    title: "登录授权已过期，请刷新当前页面重新授权登录",
    duration: 2000,
    icon: "none"
  }); */
  router.push("/login")
  localStorage.removeItem("info");
  localStorage.removeItem("token");
  localStorage.removeItem("loginDate")
  localStorage.removeItem("code");
  localStorage.removeItem("isScrmPhone");
  localStorage.removeItem("isSourceAdmin")
  localStorage.removeItem("popped")
  localStorage.removeItem("tabs")
}
/***
 *  @return {string} timeText 返回系统时间字符串
 */
 export const  getDataTimeSec = (val,type) => {
     if(!val){
         //没有日期返回空
         return "";
     }
  //时间数字小于10，则在之前加个“0”补位。
  function check(i) {
    let num;
    i < 10 ? num = "0" + i : num = i;
    return num;
  }
  let time = val ? new Date(val) : new Date();
  let year = time.getFullYear();
  let month = time.getMonth() + 1;
  let day = time.getDate();
  //获取时分秒
  let h = time.getHours();
  let m = time.getMinutes();
  let s = time.getSeconds();
  //检查是否小于10
  month = check(month)
  day = check(day)
  h = check(h);
  m = check(m);
  s = check(s);
  let timeText = ''
  if(type == 'yy-mm-dd'){
     timeText =  `${year}-${month}-${day}`;
  }else if(type == 'hh:mm'){
    timeText =  `${h}:${m}`;
  }else{
    timeText =  `${year}-${month}-${day} ${h}:${m}:${s}`;
  }
  return timeText
}

// input 禁止输入非法字符
export function noSpecialCode(e, type) {
    let pattern = "";
    switch (type) {
      case 1:
        pattern = /[\D]*/g;
        break;
      case 2:
        pattern = /[^A-Z\d]*/g;
        break;
      default:
        pattern = /[^0-9A-Za-z\u4e00-\u9fa5]*/g;
        break;
    }
    return e.replace(pattern, "");
  }

export const baseUrl = 'https://scrm.86122m.com'  // http://10.10.0.191:8666,,http://crm.86122m.com

export default {
  clearLoginInfo, baseUrl
};
