<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  data(){
    return{}
  },
  created(){
    let host = window.location.host;
    if (host.startsWith("localhost")||address.startsWith("scrm.86122m.com")){
      document.title = '码客通后台';
    }else if(host.startsWith("pdm.86122m.com")){ // fyzsm.cqm.cn
      document.title = '涿溪PDM产品数据管理系统';
    }else if(host.startsWith("plm.86122m.com")){ // fyzsm.cqm.cn
      document.title = '涿溪PLM产品生命周期管理系统';
    }else if(host.startsWith("wms.86122m.com")){ // fyzsm.cqm.cn
      document.title = '涿溪WMS仓储管理系统';
    }else if(host.startsWith("crm.86122m.com")){ // fyzsm.cqm.cn
      document.title = '涿溪CRM客户关系管理系统';
    }else if(host.startsWith("fyzsm.idshanxi.com")){ // fyzsm.cqm.cn
      document.title = '方圆证书码系统';
    }else if(host.startsWith("fycpm.idshanxi.com")) { // fycpm.cqm.cn
      document.title = '方圆产品追溯系统';
    }else if(host.startsWith("fymp.86122m.com")) { // fycpm.cqm.cn
      document.title = '基于标识的产品铭牌管理系统';
    }else if(host.startsWith("fyxj.86122m.com")) { // fycpm.cqm.cn
      document.title = '基于标识的巡检系统';
    }else if(host.startsWith("fycrk.86122m.com")) { // fycpm.cqm.cn
      document.title = '基于标识的产品快速出入库系统';
    }else if(host.startsWith("fyfch.86122m.com")) { // fycpm.cqm.cn
      document.title = '基于标识的产品防窜货管理系统';
    }else {
      document.title = '广州标准化研究院';
    }
    console.log('app')
  }
};
</script>

<style>
  .clearfix:after {
    content: ".";
    clear: both;
    height: 0;
    visibility: hidden;
    display: block;
  }

  .clearfix {
    zoom: 1;
  }
</style>
