
import {get} from "@/utils/request";

export  default {
  data(){
    return {
      queryParam:{page: 1,pageSize: 10},
      tableData: [],
      loading: false,
      total: 0,
        url: '',
        params:{}
    }
  },
  methods:{
    handleSizeChange(e){
       this.queryParam.pageSize=e;
       this.queryParam.pageNum=1
        this.initList(this.url)
    },
    handleCurrentChange(e){
        this.queryParam.pageNum = e;
        this.initList(this.url)
    },
    initList(url,params){
        this.url=url?url:this.url
        if (params){
            this.params = params
            Object.assign(this.queryParam,params)
        }
       get(this.url,this.queryParam).then(res=>{
           this.tableData=res.list
           this.total=res.total
           this.initFinish()
       })
    },
    initFinish(){

    }
  }
}
