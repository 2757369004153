import store from "@/store";

export  function  formatAmount(amount){
  if(!amount){
    return  '0.00'
  }
  return (amount/100).toFixed(2) + '';
}

export function showToast(msg){
  this.$message({
    type: 'info',
    message: msg
  });
}

export function showErrorToast(self,msg){
  self.$message({
    type: 'error',
    message: msg
  });
}

export function add0(m) {
  return m < 10 ? '0' + m : m
}

export function formatTimes(shijianchuo) {
  //shijianchuo是整数，否则要parseInt转换
  var time = new Date(parseInt(shijianchuo + '000'));
  var y = time.getFullYear();
  var m = time.getMonth() + 1;
  var d = time.getDate();
  var h = time.getHours();
  var mm = time.getMinutes();
  var s = time.getSeconds();
  return y + '-' + add0(m) + '-' + add0(d) + ' ' + add0(h) + ':' + add0(mm) + ':' + add0(s);
}

//时间格式转换
export function getFormatTime1(timeStamp,accurate){
      var dateTime = new Date(timeStamp);
      var no1new = dateTime.valueOf();
      var year = dateTime.getFullYear();
      var month = dateTime.getMonth() + 1;
      var day = dateTime.getDate();
      var hour = dateTime.getHours();
      var minute = dateTime.getMinutes();
      var second = dateTime.getSeconds();
      var now = new Date();
      var now_new = now.valueOf();
      var milliseconds = 0;
      var timeSpanStr;
      if(hour < 10){
        hour = "0" + hour
      }if(minute < 10){
        minute = "0" + minute
      }

      milliseconds = now_new - no1new;

      if (milliseconds <= 1000 * 60 * 1) {
          timeSpanStr = '刚刚';
      }else if (1000 * 60 * 1 < milliseconds && milliseconds <= 1000 * 60 * 60) {
          timeSpanStr = Math.round((milliseconds / (1000 * 60))) + '分钟前';
      }else if (1000 * 60 * 60 * 1 < milliseconds && milliseconds <= 1000 * 60 * 60 * 24) {
          timeSpanStr = Math.round(milliseconds / (1000 * 60 * 60)) + '小时前';
      }else if (1000 * 60 * 60 * 24 < milliseconds && milliseconds <= 1000 * 60 * 60 * 24 * 15) {
          timeSpanStr = Math.round(milliseconds / (1000 * 60 * 60 * 24)) + '天前';
      }else if(accurate == true){
          timeSpanStr = year + '-' + month + '-' + day + ' ' + hour + ':' + minute
      }else if (milliseconds > 1000 * 60 * 60 * 24 * 15 && year == now.getFullYear()) {
        timeSpanStr = year + '-' + month + '-' + day
      }else{
        timeSpanStr = year + '-' + month + '-' + day
      }
   return timeSpanStr;
  }

export function versionTimes(shijianchuo) {
  //shijianchuo是整数，否则要parseInt转换
  var time = new Date(parseInt(shijianchuo));
  var y = time.getFullYear();
  var m = time.getMonth() + 1;
  var d = time.getDate();
  var h = time.getHours();
  var mm = time.getMinutes();
  var s = time.getSeconds();
  return y + '' + add0(m) + '' + add0(d);
}

export function formatDate(str) {
  let d = new Date(str)
  let time = d.getFullYear() + "年" + (d.getMonth() + 1).toString().padStart(2,'0') + "月" + d.getDate().toString().padStart(2,'0') + "日";
  return time
}

import Roots from './root.js'
import role from "@/pages/businessManagement/role.vue";
export function filterUserRoot(myRoot){
  let userInfo = JSON.parse(localStorage.getItem('info'));
  let isHaveMall= JSON.parse(userInfo.isHaveMall) // 功能权限
  let isMkc = JSON.parse(userInfo.isMkc)

  let isMkt = JSON.parse(userInfo.isMkt)
  if(isMkt) {
    isHaveMall = true
  }
  let isHaveNfc = JSON.parse(userInfo.isHaveNfc)
  let resRoutes = []
  let userRoot = []
  if(!myRoot) {
    userRoot = Roots.userRoot
  }
  if(isMkc && (!isMkt)) { // 只买码客查
    let delMenu = [
     '01',
     '07',
     '08',
     '010'
   ]
   let arr1 = userRoot.filter(item => !delMenu.includes(item.id))
   if(!isMkt) {
     arr1.filter(item => item.id == '02')[0].children.splice(1,3)
   }
   if(!isHaveMall) {
     let delMenu2 = [
       '011', //商城设置
       '03', //订单管理
       '04', //商品管理
       '05', //营销活动
       '06', //财务管理
     ]
     arr1 = arr1.filter(item => !delMenu2.includes(item.id))
   }
   resRoutes = arr1
 }else if(isMkt && (!isMkc)) { // 只买码客通
   let delMenu = [
     '012', //模版管理
     '014' //任务管理
   ]
   let arr1 = userRoot.filter(item => !delMenu.includes(item.id))
   if(!isHaveMall) {
     let delMenu2 = [
      '011', //商城设置
      '03', //订单管理
      '04', //商品管理
      '05', //营销活动
      '06', //财务管理
     ]
     arr1 = arr1.filter(item => !delMenu2.includes(item.id))
   }
   resRoutes = arr1
 }else if((!isMkt) && (!isMkc)){ // 都没买
   let delMenu3=[
       '01',
       '03',
       '04',
       '05',
       '06',
       '07',
       '08', 
       '010', 
       '011', 
       '012', 
       '013',
       '014'
   ]

   let arr1 = userRoot.filter(item => !delMenu3.includes(item.id))
   if(!isMkt) {
     arr1.filter(item => item.id === '02')[0].children.splice(1,3)
   }
   resRoutes = arr1
 }else {
   resRoutes = userRoot
 }
  return resRoutes
}



// ascending 升序  descending降序
export function SortByProps(item1, item2, obj) {
  var props = [];
  if (obj) {
      props.push(obj)
  }
  var cps = [];
  // 存储排序属性比较结果。
  // 如果未指定排序属性(即obj不存在)，则按照全属性升序排序。
  // 记录下两个排序项按照各个排序属性进行比较得到的结果    
  var asc = true;
  if (props.length < 1) {
      for (var p in item1) {
          if (item1[p] > item2[p]) {
              cps.push(1);
              break;
              // 大于时跳出循环。
          } else if (item1[p] === item2[p]) {
              cps.push(0);
          } else {
              cps.push(-1);
              break;
              // 小于时跳出循环。
          }
      }
  } else {
      for (var i = 0; i < props.length; i++) {
          var prop = props[i];
          for (var o in prop) {
              asc = prop[o] === "ascending";
              if (item1[o] > item2[o]) {
                  cps.push(asc ? 1 : -1);
                  break;
                  // 大于时跳出循环。
              } else if (item1[o] === item2[o]) {
                  cps.push(0);
              } else {
                  cps.push(asc ? -1 : 1);
                  break;
                  // 小于时跳出循环。
              }
          }
      }
  }
  // 根据各排序属性比较结果综合判断得出两个比较项的最终大小关系
  for (var j = 0; j < cps.length; j++) {
      if (cps[j] === 1 || cps[j] === -1) {
          return cps[j];
      }
  }
  return false;
}


export  function deepClone(source) {
    if (!source && typeof source !== 'object') {
        throw new Error('error arguments', 'deepClone')
    }
    const targetObj = source.constructor === Array ? [] : {}
    Object.keys(source).forEach(keys => {
        if (source[keys] && typeof source[keys] === 'object') {
            targetObj[keys] = deepClone(source[keys])
        } else {
            targetObj[keys] = source[keys]
        }
    })
    return targetObj
}