let userRoot = [{
  icon: "#icon-dashboard",
  index: "dashboard",
  label:'工作台',
  isSelect:true,
  id:'00',
  visible: false,
},
  {
  icon: "#icon-team",
  index: "customer",
  label:'客户管理',
  isSelect:true,
  id:'01',
  visible: false,
  children: [
    {
      index: "customerManagement",
      label: '客户管理',
      isSelect:true,
      id: '010',
      visible: false,
    },
    // {
    //   index: "fitup",
    //   label: '店铺装修',
    //   isSelect:true,
    //   id: '0101',
    //   visible: false,
    // },
    // {
    //   index: "freight",
    //   label: '运费管理',
    //   isSelect:false,
    //   id: '041',
    //   visible: false,
    // },
    // {
    //   index: "goodGroup",
    //   label: '商品组管理',
    //   isSelect:false,
    //   id: '042',
    //   visible: false,
    // },
  ]
},{
  icon: "#icon-bank",
  index: "companyManagement",
  label:'企业管理',
  isSelect:true,
  id:'02',
  visible: false,
  children: [
    {
      index: "corporateInformation",
      label: '企业信息',
      isSelect:true,
      id: '020',
      visible: false,
    },
    {
      index: "enterpriseMember",
      label: '企业成员',
      isSelect:true,
      id: '021',
      visible: false,
      // children:[
      //   {
      //     label: '部门设置',
      //     isSelect:false,
      //     id: '0210',
          // children:[
          //   {
          //     label: '仅可设置自己负责的部门',
          //     isSelect:true,
          //     id: '02100',
          //   },
          //   {
          //     label: '可设置所有部门',
          //     isSelect:true,
          //     id: '02101',
          //   },
          // ]
        // },
        // {
        //   label: '管理员设置',
        //   isSelect:false,
        //   id: '0211',
        // },
        // {
        //   label: '成员设置',
        //   isSelect:false,
        //   id: '0212',
        // },
        // {
        //   label: '设置员工目标',
        //   isSelect:false,
        //   id: '0213',
        // }
      // ]
    },
    {
      index: "advancedForm",
      label: '自定义反馈表',
      isSelect:false,
      id: '022',
      visible: false,
    },
    {
      index: "advertisingManagement",
      label: '广告图管理',
      isSelect:false,
      id: '023',
      visible: false,
    },
    {
      index: "dealerManage",
      label: '经销商管理',
      isSelect:false,
      id: '024',
      visible: false,
    },
  ],
},{
  icon: "#icon-file-text",
  index: "orderManagement",
  label:'订单管理',
  isSelect:false,
  id:'03',
  visible: false,
  children:[
    {
      index: "orderManagement",
      label: '订单管理',
      isSelect:false,
      id: '030',
      visible: false,
    },
    {
      index: "evaluateManagement",
      label: '评价管理',
      isSelect:false,
      id: '031',
      visible: false,
    },
    {
      index: "noticeManagement",
      label: '通知管理',
      isSelect:false,
      id: '032',
      visible: false,
    },
  ]
},{
  icon: "#icon-shopping",
  index: "merchandiseManagement",
  label:'商品管理',
  isSelect:false,
  id:'04',
  visible: false,
  children:[
    {
      index: "management",
      label: '商品管理',
      isSelect:false,
      id: '040',
      visible: false,
    },

  ]
},{
  icon: "#icon-gift",
  index: "marketingActivities",
  label:'营销活动',
  isSelect:false,
  id:'05',
  visible: false,
  children:[
    {
      label: '分销',
      index: "distribution",
      isSelect:false,
      id: '050',
      visible: false,
      children:[
        {
          index: "distributionStatistics",
          label: '分销统计',
          isSelect:false,
          id: '0500',
          visible: false,
        },
        {
          index: "personnelApplication",
          label: '人员申请',
          isSelect:false,
          id: '0501',
          visible: false,
        },
        {
          index: "distributionLevel",
          label: '分销等级',
          isSelect:false,
          id: '0502',
          visible: false,
        },
        {
          index: "distributionOrder",
          label: '分销订单',
          isSelect:false,
          id: '0503',
          visible: false,
        },
        {
          index: "accountTransfer",
          label: '到账流水',
          isSelect:false,
          id: '0504',
          visible: false,
        },
      ]
    },
    {
      index: "joinGroup",
      label: '拼团设置',
      isSelect:false,
      id: '051',
      visible: false,
      children:[
        {
          index: "productList",
          label: '拼团商品列表',
          isSelect:false,
          id: '0510',
          visible: false,
        },
        {
          index: "listParticipants",
          label: '参团列表',
          isSelect:false,
          id: '0511',
          visible: false,
        },
      ]
    },
    {
      index: "productList",
      label: '秒杀设置',
      isSelect:false,
      id: '052' ,
      visible: false,
      children:[
        {
          index: "spikeList",
          label: '秒杀商品列表',
          isSelect:false,
          id: '0520',
          visible: false,
        },
      ]
    },
    {
      index: "couponManagement",
      label: '优惠券设置',
      isSelect:false,
      id: '053' ,
      visible: false,
      children:[
        {
          index: "couponList",
          label: '优惠券列表',
          isSelect:false,
          id: '0530',
          visible: false,
        },
      ]
    },
    {
      index: "newAction",
      label: '满减活动',
      isSelect:false,
      id: '01090',
      visible: false,
      children:[
        {
          index: "newAction",
          label: '满减活动',
          isSelect:false,
          id: '01091',
          visible: false,
        },
      ]
    },
  ]
},{
  icon: "#icon-creditcard",
  index: "financialManagement",
  label:'财务管理',
  isSelect:false,
  id:'06',
  visible: false,
  children:[
    {
      index: "commissionManagement",
      label: '佣金管理',
      isSelect:false,
      id: '060',
      visible: false,
    },
    {
      index: "Merchant",
      label: '商户管理',
      isSelect:false,
      id: '061',
      visible: false,
    },
  ]
},{
  index: "articleManagement",
  icon: "#icon-solution",
  label:'获客文章管理',
  isSelect:false,
  id:'07',
  visible: false,
  children:[
    {
      index: "customerArticles",
      label: '获客文章管理',
      isSelect:false,
      id: '070' ,
      visible: false,
    },
    {
      index: "employeeData",
      label: '员工数据',
      isSelect:false,
      id: '071',
      visible: false,
    },
    {
      index: "visitorData",
      label: '访客数据',
      isSelect:false,
      id: '072',
      visible: false,
    },
  ]
},{
  index: "videoManage",
  icon: "#icon-video",
  label:'短视频管理',
  isSelect:false,
  id:'08',
  visible: false,
  // children:[
  //   {
  //     label: '添加/编辑/设置短视频(成员自己)',
  //     isSelect:false,
  //     id: '080'
  //   },
  //   {
  //     label: '编辑/设置短视频(其他成员)',
  //     isSelect:false,
  //     id: '081'
  //   },
  // ]
},
// {
//   label:'增值服务',
//   isSelect:false,
//   id:'09',
//   children:[
//     {
//       label: '需求广场审核/举报设置',
//       isSelect:false,
//       id: '090'
//     },
//     {
//       label: '产品中心审核设置',
//       isSelect:false,
//       id: '091'
//     },
//     {
//       label: '人脉集市审核设置',
//       isSelect:false,
//       id: '092'
//     },
//     {
//       label: '发布设置',
//       isSelect:false,
//       id: '093'
//     },
//     {
//       label: '自定义设置',
//       isSelect:false,
//       id: '094'
//     },
//   ]
// },
  // {
  //   label:'分享推广管理',
  //   icon:'#icon-share',
  //   index: "sharingPromotion",
  //   isSelect:false,
  //   id:'010',
  //   visible: false,
  //   children:[
  //     {
  //       label: '海报图集',
  //       index: "posterAtlas",
  //       isSelect:false,
  //       id: '0100',
  //       visible: false,
  //     },
  //     {
  //       label: '打卡数据',
  //       index: "clockData",
  //       isSelect:false,
  //       id: '0101' ,
  //       visible: false,
  //     },
  //   ]
  // },
  {
    icon: "#icon-api",
    index: "miniProgram",
    label:'商城设置',
    isSelect:false,
    id:'011',
    visible: false,
    children:[
      {
        index: "mallSetting",
        label: '商城设置',
        isSelect:false,
        id: '0110' ,
        visible: false,
      },
      // {
      //   index: "fitup",
      //   label: '店铺装修',
      //   isSelect:true,
      //   id: '0101',
      //   visible: false,
      // },
      {
        index: "freight",
        label: '运费管理',
        isSelect:false,
        id: '041',
        visible: false,
      },
      // {
      //   index: "goodGroup",
      //   label: '商品组管理',
      //   isSelect:false,
      //   id: '042',
      //   visible: false,
      // },
    ]
  },
  {
    icon: "#icon-CI",
    label:'模版管理',
    index: "customPage",
    isSelect:false,
    id:'012',
    visible: false,
    children:[
      {
        index: "customPageList",
        label: '模版列表',
        isSelect:false,
        id: '0120' ,
        visible: false,
      },
      {
        index: "createPageList",
        label: '页面列表',
        isSelect:false,
        id: '0121' ,
        visible: false,
      },
      {
        index: "pageClass",
        label: '页面类别',
        isSelect:false,
        id: '0201' ,
        visible: false,
      },
      {
        index: "configHandleType",
        label: '数据模版',
        isSelect:false,
        id: '0122' ,
        visible: false,
      },
      {
        index: "configTemplate",
        label: '标识模版',
        isSelect:false,
        id: '0123' ,
        visible: false,
      },
      {
        index: "handleCustomer",
        label: '客户管理',
        isSelect:false,
        id: '0124',
        visible: false,
      },
      {
        index: "imgManage",
        label: '图片管理',
        isSelect:false,
        id: '0126' ,
        visible: false,
      },
    ]
  },
  {
    icon: "#icon-smile",
    index: "publicAbout",
    label:'公众号',
    isSelect:false,
    id:'013',
    visible: false,
    children:[
      {
        label: '公众号相关',
        index: "publicAbout",
        isSelect:false,
        id: '0130' ,
        visible: false,
      },
    ]
  },
  {
    icon: "#icon-shopping",
    index: "vipManage",
    label:'会员管理',
    isSelect:false,
    id:'015',
    visible: false,
    children:[
      {
        index: "vipManage",
        label: '会员管理',
        isSelect:false,
        id: '0150' ,
        visible: false,
      },
    ]
  },
  {
    icon: "#icon-earth",
    index: "printManage",
    label:'任务管理',
    isSelect:false,
    id:'014',
    visible: false,
    children:[
      // {
      //   index: "taskIndex",
      //   label: '任务管理',
      //   isSelect:false,
      //   id: '0140' ,
      //   visible: false,
      // },
      {
        index: "specialIndex",
        label: '箱盒任务',
        isSelect:false,
        id: '0141' ,
        visible: false,
      },
      {
        index: "printIndex",
        label: '设备管理',
        isSelect:false,
        id: '0142' ,
        visible: false,
      },
      // {
      //   index: "screenIndex",
      //   label: '打印大屏',
      //   isSelect:false,
      //   id: '0143' ,
      //   visible: false,
      // },
    ]
  },
  {
    icon: "#icon-shopping",
    index: "ryt",
    label:'任意贴',
    isSelect:false,
    id:'035',
    visible: false,
    children:[
      {
        index: "rytStatistics",
        label: '任意贴统计',
        isSelect:false,
        id: '0145',
        visible: false,
      },
      {
        index: "rytModel",
        label: '任意贴模板',
        isSelect:false,
        id: '0146',
        visible: false,
      },
      {
        index: "rytHandle",
        label: '任意贴标识码',
        isSelect:false,
        id: '0147',
        visible: false,
      },
     
    ]
  }
  // {
  // icon: "#icon-star",
  // index: "verManage",
  // label:'更新日志',
  // isSelect:false,
  // id:'2001',
  // visible: false,
  // }
]

// let userInfo = JSON.parse(localStorage.getItem('info'))
// if(!userInfo.main){
//   userRoot.forEach((item,index) =>{
//     console.log(item)
//     if(item.id == '09' || item.id == '10' ){
//       userRoot.splice(index,1)
//     }

//   })
//   userRoot.forEach((item,index) =>{
//     if(item.id == '010'){
//       userRoot.splice(index,1)
//     }

//   })

// }
console.log(userRoot,'userRoot')


export default {
  userRoot
}
