<template>
	<div>
		<el-table :row-key="keyId" @row-click="clickRow" v-loading="loading" ref="multipleTable" :data="tableData"
			:span-method="spanMethod" border tooltip-effect="dark" :style="{marginTop:marginTop}"
			@selection-change="SelectionChange" highlight-current-row @select="selectFun" :row-class-name="rowClassName">
			<slot name="table"></slot>
		</el-table>

		<div style="display: flex; justify-content: flex-end; margin-top: 16px" v-if="tableData.length !== 0 ">
			<!-- <div style="display: flex; justify-content: flex-end; margin-top: 40px" v-if='tableData.length !== 0 && hideTable == false'> -->


			<el-pagination v-if="paginationVisible" background @size-change="sizeChange" @current-change="currentChange"
				:current-page="currentPage" :page-sizes="[10, 15, 20]" layout="total, sizes, prev, pager, next, jumper" class=""
				:total="total" :pager-count="5" />
		</div>
	</div>
</template>

<script>
	export default {
		name: "commonTable",
		props: {
			ids: {
				type: Array,
				default: () => [],
			},
			allData: {
				type: Array,
				default: () => [],
			},
			tableData: {
				type: Array,
				default: () => [],
			},
			hideTable: { //显隐表单
				type: Boolean,
				default: false
			},
			spanMethod: {
				type: Function,
				default: () => {},
			},
      rowClassName:{
        type: Function,
        default: () => {},
      },
			total: {
				//总条数
				type: Number,
				default: 0,
			},

			currentPage: {
				//当前页数
				type: Number,
				default: 1,
			},

			loading: {
				//表格加载
				type: Boolean,
				default: false,
			},

			handleSizeChange: {
				//监听切换条数
				type: Function,
				default: () => {},
			},

			handleCurrentChange: {
				type: Function,
				default: () => {},
			},
			handleSelectionChange: {
				type: Function,
				default: () => {},
			},
			handleClickRow: {
				type: Function,
				default: () => {},
			},
			paginationVisible: {
				//表格加载
				type: Boolean,
				default: true,
			},
			clearName: {
				type: String,
				default: '',
			},
			itemKey: {
				type: String,
				default: '',
			},
			marginTop: {
				type: String,
				default: '24px',
			},
			keyId: {
				type: String,
				default: '',
			},
		},
		data() {
			return {
				computedClearName: [
					'demandProtuctsCheck',
				]
			};
		},
		mounted() {
			this.getSelec()
		},
		methods: {
			selectFun(row) {
				console.log(row)
			},
			getSelec() {
				var arr = this.tableData
				var arr1 = this.ids
				this.$nextTick(()=> {
					arr.map(row=> {
						arr1.map(item=> {
							
							if(item == row.userId) {
								console.log(item + '===' + row.userId)
								this.$refs.multipleTable.toggleRowSelection(row, true);
							}
						})
					})
				})
			},
			setCurrent(row) {
				this.$nextTick(() => {
					this.tableData.forEach(row => {
						// multipleTable :弹窗中table 写上 ref="multipleTable "
						this.$refs.multipleTable.toggleRowSelection(row, false);
					});
				})
			},

			/**@method 监听切换条数并调用父组件方法 */
			sizeChange(val) {
				this.$emit("handleSizeChange", val);
			},
			/**@method 监听下一页并调用父组件方法 */
			currentChange(val) {
				this.$emit("handleCurrentChange", val);
			},
			SelectionChange(val) {
				// if( this.computedClearName.includes(this.clearName)){
				//   const item = val.find(item =>{return item[this.itemKey] != "0"});
				//   if(item){
				//     this.$refs.multipleTable.clearSelection();
				//     this.$message({
				//       message: "请选择审核中的需求",
				//       type: "warning",
				//     });
				//     return;
				//   }
				// }
				this.$emit("handleSelectionChange", val);
			},
			clickRow(row) {
				this.$emit("handleClickRow", row);
			},
			clearSelection() {
				this.$refs.multipleTable.clearSelection();
			},
			table() {
				return this.$refs.multipleTable
			},
		},
	};
</script>

<style lang="scss" scoped>

</style>