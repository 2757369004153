<template>
  <div>
    <slot  name="left"></slot>
    <el-button  :loading="editLoading" :disabled="disabledEdit" size="mini" type="text" @click="toEdit" v-if="showDel">编辑</el-button>
    <el-popover v-model="pop" placement="top" width="180" trigger="manual" @show="onPopoverShow" @hide="onPopoverHide">
      <p>{{msg}}</p>
      <div style="text-align: right; margin: 0">
        <el-button size="mini" type="text" @click="doCancel">取消</el-button>
        <el-button :loading="delLoading" type="primary" size="mini" @click="doDel">确定</el-button>
      </div>
      <el-button slot="reference" :disabled="disabledDel" type="text" size="mini" @click="toDelete" style="color: red" v-if="showDel">删除</el-button>
    </el-popover>
    <slot  name="right"></slot>
  </div>
</template>
<script>
import {deletes} from "@/utils/request";

export default {
  props: {
    delId: {

    },
    data: {
      type: Object,
      required: true
    },
    disabledEdit: {
      type: Boolean,
      default: false
    },
    disabledDel: {
      type: Boolean,
      default: false
    },
    msg: {
      type: String,
      default: '确定删除本条数据吗？'
    },
    showDel:{
      type : Boolean,
      default: true
    },
    showEdit:{
      type : Boolean,
      default: true
    },
    url:{}
  },
  data() {
    return {
      editLoading: false,
      delLoading: false,
      pop: false
    }
  },
  methods: {
    toEdit(){
      this.$emit("edit")
    },
    doDel(){
       deletes(this.url,[this.delId]).then(res=>{
         this.pop=false
         this.$emit("refresh")
       })
    },
    doCancel() {
      this.pop = false
      //this.crud.cancelDelete(this.data)
    },
    toDelete() {
      this.pop = true
    },
    onPopoverShow() {
      setTimeout(() => {
        document.addEventListener('click', this.handleDocumentClick)
      }, 0)
    },
    onPopoverHide() {
      document.removeEventListener('click', this.handleDocumentClick)
    },
    handleDocumentClick(event) {
      this.pop = false
    }
  }
}
</script>
