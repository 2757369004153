import Vue from 'vue'
import App from './App.vue'
import './assets/css/main.css'
import ElementUI from 'element-ui'
import router, {asyncRouters} from './router'
import 'element-ui/lib/theme-chalk/index.css'; // 默认主题
import './assets/theme/index.css' // 自定义主题
import { Message } from 'element-ui'
import store from '@/store'
import '@/assets/js/iconfont.js';
import QuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.bubble.css'
import 'quill/dist/quill.snow.css'
import {pcLoginCallBack} from './api/login'
import vuex from '@/store/index'
import echarts from 'echarts'
import permission from "@/directives";
Vue.prototype.$echarts = echarts

Vue.use(permission)

import ElTreeSelect from 'el-tree-select';

import htmlToPdf from '@/utils/htmlToPdf';
// 使用 Vue.use() 方法就会调用工具方法中的install方法
Vue.use(htmlToPdf);
Vue.use(ElTreeSelect);

import {get} from "@/utils/request";

/***经销商地图 */
//高德的安全密钥
window._AMapSecurityConfig = {
	securityJsCode:'c100a1dec521518acc0fa32dee77d9d0',
}
// 高德地图
import VueAMap from 'vue-amap'
import {deepClone} from "@/utils/utils";
// 初始化vue-amap
VueAMap.initAMapApiLoader({
	key: '872463d5f9926f3560506443698de6f0',// 高德的key
	// 插件集合
	plugin: [
		// 'AMap.CircleEditor',// 圆形编辑器插件
		"AMap.Geolocation", // 定位控件，用来获取和展示用户主机所在的经纬度位置
		"AMap.Geocoder", // 地理编码与逆地理编码服务，用于地址描述与坐标间的相互转换
		"AMap.Autocomplete",
		"AMap.PlaceSearch",
		"AMap.CitySearch",
    "AMap.PolyEditor",
    "AMap.MarkerClusterer"
	],
	// 高德 sdk 版本，默认为 1.4.4
	v: '2.0'
});
Vue.use(VueAMap);

Vue.use(QuillEditor)

Vue.config.productionTip = false


Vue.use(ElementUI, {
  size: 'small'
})
Vue.prototype.$message = Message


var hasRole=false

router.beforeEach(async (to, form, next) => {


  if (to.path === '/down' || to.path === '/userVerify' || to.path === '/crkDown') {
      if(to.query.info) {
          to.query.info = to.query.info.replace(/\s/g, '+')
      }
    next()
    return;
  }
  //login登录授权回调
  if(to.query.code){
    //保存code 回调成功后 删除
    localStorage.setItem('code', to.query.code);
    //请求扫码回调验证 验证通过保存token
    get("/home/pcLoginCallBack",{code:to.query.code,appId:to.query.state}).then(res=>{
			console.log("扫码回调:",res)
			if(res.token) {
        localStorage.removeItem('isSourceAdmin')
				//存储登录用户信息
        localStorage.setItem('info', JSON.stringify(res));

				//存储token
				localStorage.setItem('token', res.token);
				localStorage.setItem('loginDate', res.lastLoginTime);

				store.state.loginRoot.loginDate = res.lastLoginTime;
				store.state.loginRoot.userInfo = res;

				localStorage.removeItem("code");
				router.push("/dashboard");

			}else {
				this.$router.push({path:'/bindAccount',query: {userId:res.userId}})
			}

    }).catch(error =>{
      localStorage.removeItem("code");
      Message({message:error ? error.message : error,type:'error'})
      router.push("/login");
    });
  }else if(to.query.auth_code){
    //授权回调下放到vue业务页面处理
    next()
  }else{
    if(!localStorage.getItem("code")){
      if (to.path === '/login') {
        next()
      }else {
        if (!localStorage.getItem('token')){
          if(to.query.token) {
            localStorage.setItem('info', JSON.stringify(to.query));
            localStorage.setItem('token', to.query.token);
            localStorage.removeItem("code");
            location.reload()
          }else {
            next('/login')
          }
        }else {
          if(to.query.token && to.query.token !== localStorage.getItem('token')) {
            localStorage.setItem('info', JSON.stringify(to.query));
            localStorage.setItem('token', to.query.token);
            localStorage.removeItem("code");
            location.reload()
          }else {
             if(store.state.hasRole){
                 console.log('有权限了')
                 next()
             }else {
                 console.log('获取权限')
                 await store.dispatch('setHasRole',true)
                 var res=await  get('/company/menu/crmPermission');
                 const asyncMenus = deepClone(asyncRouters)
                 var menus=filterAsyncMenu(asyncMenus,res.permissions)
                 await store.dispatch("setAsyncMenus",menus)
                 await  store.dispatch("setBtnList",res.btn)
                 // menus.forEach(item=>{
                 //     router.addRoute(item)
                 // })
                 router.addRoutes(menus)
                 console.log('获取到权限',menus)
                 next({ ...to, replace: true })
             }

          }
        }
      }
    }
  }
  // let userInfo = JSON.parse(localStorage.getItem('info'));
  // if(userInfo.roleId){
  //   return
  // }
  // queryEmpRouter({a:''})
  // .then(res=>{
  //   if(res.data && res.data.routerId) {
  //     let arr = JSON.parse(res.data.routerId)
  //     vuex.commit('setRouterArr',arr)
  //
  //     vuex.commit('setEmpId',res.data.empId)
  //
  //     console.log(vuex)
  //   }
  //
  // })
})

function  filterAsyncMenu(routeList,permissions){
    return routeList.filter((item,index)=>{
        if(item.children){
            item.children=filterAsyncMenu(item.children,permissions)
        }
        return permissions.includes(item.meta.permission)
    })
}

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
